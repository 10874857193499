<template>
  <el-card shadow="never">
    <template #header>
      <i class="el-icon-house"></i>&nbsp;
      <span>首页管理</span>
    </template>
    <div v-if="isload" class="content">
      <div class="content-header">
        <el-tabs
          v-model="activeName"
          type="border-card"
          @tab-click="handleClick"
        >
          <el-tab-pane label="BANNER" name="banner">
            <el-row>
              <el-col>
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('banner')"
                  >新增 BANNER</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="banner" width="100%">
                <el-table-column label="Banner图" width="180">
                  <template #default="scope">
                    <el-image class="pdt-thumb" :src="scope.row.src" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="linkname"
                  label="链接"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.linktype == 'goodslist'"
                      type="text"
                      @click="selGoods('banner', scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('banner', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('banner', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('banner', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('banner', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('banner', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="广告语" name="adslogan">
            <el-row>
              <el-col>
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('adslogan')"
                  >新增广告语</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="adslogan" width="100%">
                <el-table-column label="广告语图片" width="260">
                  <template #default="scope">
                    <el-image class="ads-thumb" :src="scope.row.src" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      type="text"
                      @click.stop="updateStatus('adslogan', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('adslogan', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('adslogan', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('adslogan', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('adslogan', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="ICON 导航" name="icon">
            <el-row>
              <el-col>
                <el-tag size="small" type="danger"
                  >同时启用的ICON导航最多只能 10
                  个，否则无法保存页面设置</el-tag
                >
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('icon')"
                  >新增 ICON 导航</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="icon" width="100%">
                <el-table-column label="图标" width="80">
                  <template #default="scope">
                    <el-image class="icon pdt-thumb" :src="scope.row.src" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                  width="200"
                />
                <el-table-column
                  prop="linkname"
                  label="链接"
                  show-overflow-tooltip
                />
                <el-table-column prop="tag" label="标签" width="100" />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.linktype == 'goodslist'"
                      type="text"
                      @click="selGoods('icon', scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('icon', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('icon', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button type="text" @click="moveUp('icon', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('icon', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('icon', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="快讯" name="news">
            <el-row>
              <el-col>
                <el-tag size="small" type="danger"
                  >同时启用的快讯最多只能 3 个，否则无法保存页面设置</el-tag
                >
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('news')"
                  >新增快讯</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="news" width="100%">
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                  width="300"
                />
                <el-table-column
                  prop="linkname"
                  label="链接"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.linktype == 'goodslist'"
                      type="text"
                      @click="selGoods('news', scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('news', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('news', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button type="text" @click="moveUp('news', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('news', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('news', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="主场活动" name="venue">
            <el-row>
              <el-col>
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('venue')"
                  >新增主场活动</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="venue" width="100%">
                <el-table-column label="背景图" width="180">
                  <template #default="scope">
                    <el-image class="icon pdt-thumb" :src="scope.row.bgimage" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="主场活动标题"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      type="text"
                      @click="contentManagement('venue', scope.$index)"
                      >内容管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('venue', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('venue', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('venue', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('venue', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('venue', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="胶囊位" name="capsule">
            <el-row>
              <el-col>
                <el-tag size="small" type="danger"
                  >同时启用的胶囊最多只能 2 个，否则无法保存页面设置</el-tag
                >
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('capsule')"
                  >新增胶囊</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="capsule" width="100%">
                <el-table-column label="背景图" width="220">
                  <template #default="scope">
                    <el-image class="icon pdt-thumb" :src="scope.row.src" />
                  </template>
                </el-table-column>
                <el-table-column
                  prop="title"
                  label="标题"
                  show-overflow-tooltip
                  width="200"
                />
                <el-table-column
                  prop="linkname"
                  label="链接"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.linktype == 'goodslist'"
                      type="text"
                      @click="selGoods('capsule', scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('capsule', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('capsule', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('capsule', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('capsule', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('capsule', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="专区" name="special">
            <el-row>
              <el-col>
                <el-tag size="small" type="danger"
                  >同时启用的专区只能 2 个或者 4
                  个，否则无法保存页面设置</el-tag
                >
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('special')"
                  >新增专区</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="special" width="100%">
                <el-table-column
                  prop="title"
                  label="主场标题"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="linkname"
                  label="链接"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="320"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      v-if="scope.row.linktype == 'goodslist'"
                      type="text"
                      @click="selGoods('special', scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('special', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('special', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('special', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('special', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('special', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="橱窗位" name="showcase">
            <el-row>
              <el-col>
                <el-button
                  type="success"
                  class="f-right"
                  @click="addItem('showcase')"
                  >新增橱窗</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table class="page-settings" :data="showcase" width="100%">
                <el-table-column
                  prop="title"
                  label="橱窗标题"
                  show-overflow-tooltip
                />
                <el-table-column label="状态" width="80">
                  <template #default="scope">
                    <label v-if="scope.row.status * 1 == 1" style="color: green"
                      >已启用</label
                    >
                    <label
                      v-else-if="scope.row.status * 1 == 0"
                      style="color: red"
                      >已停用</label
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  label="操作"
                  width="360"
                  class-name="row-control"
                >
                  <template #default="scope">
                    <el-button
                      type="text"
                      @click="contentManagement('ad', scope.$index)"
                      >广告</el-button
                    >
                    <el-button
                      type="text"
                      @click="contentManagement('menus', scope.$index)"
                      >菜单管理</el-button
                    >
                    <el-button
                      type="text"
                      @click.stop="updateStatus('showcase', scope.$index)"
                      :class="scope.row.status * 1 == 1 ? 'delete' : ''"
                      >{{
                        scope.row.status * 1 == 0 ? "启用" : "停用"
                      }}</el-button
                    >
                    <el-button
                      type="text"
                      @click="addItem('showcase', scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('showcase', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('showcase', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="delItem('showcase', scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>

          <el-tab-pane label="商品集合" name="goodslist">
            <el-row>
              <el-col>
                <div
                  style="
                    float: left;
                    display: flex;
                    align-items: center;
                    height: 100%;
                  "
                >
                  <span style="font-size: 14px; margin-right: 10px"
                    >展示模式:</span
                  >
                  <el-select
                    v-model="goodslist.mode"
                    style="width: 120px"
                    class="blockitem"
                    size="small"
                    @change="changeListMode"
                  >
                    <el-option label="正常模式" :value="1"></el-option>
                    <el-option label="榜单模式" :value="2"></el-option>
                  </el-select>
                  <span style="font-size: 14px; margin: 0 10px"
                    >每行商品个数:</span
                  >
                  <el-input-number
                    v-model="goodslist.column"
                    :min="1"
                    :max="2"
                    controls-position="right"
                    style="width: 100px; margin-right: 10px"
                    :disabled="goodslist.mode == 2"
                    size="small"
                    @change="saveConfig('goodslist')"
                  />
                  <el-tag type="danger">最多支持 2 个</el-tag>
                </div>
                <el-button type="success" class="f-right" @click="addGoodsMenu"
                  >新增集合导航</el-button
                >
              </el-col>
            </el-row>

            <div class="content-main">
              <el-table
                class="pdt-list"
                :data="goodslist.items"
                width="100%"
                style="margin-top: 20px"
              >
                <el-table-column prop="maintitle" label="主标题" width="220" />
                <el-table-column label="显示" width="80">
                  <template #default="scope">
                    <label
                      v-if="scope.row.mainshow * 1 == 1"
                      style="color: green"
                      >是</label
                    >
                    <label
                      v-else-if="scope.row.mainshow * 1 == 0"
                      style="color: red"
                      >否</label
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="subtitle" label="副标题" width="220" />
                <el-table-column label="显示" width="80">
                  <template #default="scope">
                    <label
                      v-if="scope.row.subshow * 1 == 1"
                      style="color: green"
                      >是</label
                    >
                    <label
                      v-else-if="scope.row.subshow * 1 == 0"
                      style="color: red"
                      >否</label
                    >
                  </template>
                </el-table-column>
                <el-table-column label="主题图片" width="100">
                  <template #default="scope">
                    <el-image
                      v-if="scope.row.thumb"
                      class="ad-thumb"
                      :src="scope.row.thumb"
                    />
                    <span v-else>--</span>
                  </template>
                </el-table-column>
                <el-table-column label="操作" class-name="row-control">
                  <template #default="scope">
                    <el-button
                      type="text"
                      @click="selGoods('goodslist', scope.$index)"
                      >商品管理</el-button
                    >
                    <el-button
                      v-if="scope.row.status * 1 == 1"
                      type="text"
                      @click="updateGoodsMenuStatus(scope.$index)"
                      class="delete"
                      >停用</el-button
                    >
                    <el-button
                      v-else
                      type="text"
                      @click="updateGoodsMenuStatus(scope.$index)"
                      >启用</el-button
                    >
                    <el-button type="text" @click="addGoodsMenu(scope.$index)"
                      >编辑</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveUp('goodslist', scope.$index)"
                      >上移</el-button
                    >
                    <el-button
                      type="text"
                      @click="moveDown('goodslist', scope.$index)"
                      >下移</el-button
                    >
                    <el-button
                      type="text"
                      @click="deleteGoodsMenu(scope.$index)"
                      class="delete"
                      >删除</el-button
                    >
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
      <el-button type="primary" @click="publishPage()" style="margin-top: 20px"
        >保存页面设置</el-button
      >
    </div>
  </el-card>

  <el-dialog
    title="BANNER 编辑器"
    v-model="isshow.banner"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.banner"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="Banner图" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.src"
            :src="curform.src"
            class="avatar"
            preview-src-list="[curform.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >Banner图最佳尺寸：702px × 280px</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curform.linktype" @change="changeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curform.linktype &&
            curform.linktype != 'notlink' &&
            curform.linktype != 'goodslist' &&
            curform.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curform.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="selectLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curform.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curform.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.banner = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('banner')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="ICON 导航编辑器"
    v-model="isshow.icon"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.icon"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="导航图标" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.src"
            :src="curform.src"
            class="avatar"
            preview-src-list="[curform.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >导航图标最佳尺寸：120px × 120px</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curform.linktype" @change="changeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curform.linktype &&
            curform.linktype != 'notlink' &&
            curform.linktype != 'goodslist' &&
            curform.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curform.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="selectLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curform.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curform.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
      <el-form-item label="标签" prop="tag">
        <el-input
          v-model="curform.tag"
          autocomplete="off"
          style="width: 217px"
        ></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.icon = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('icon')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="快讯编辑器"
    v-model="isshow.news"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.news"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curform.linktype" @change="changeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curform.linktype &&
            curform.linktype != 'notlink' &&
            curform.linktype != 'goodslist' &&
            curform.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curform.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="selectLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curform.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curform.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.news = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('news')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="胶囊编辑器"
    v-model="isshow.capsule"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.capsule"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="背景图" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.src"
            :src="curform.src"
            class="avatar"
            preview-src-list="[curform.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >胶囊背景图最佳尺寸：702px × 240px</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curform.linktype" @change="changeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curform.linktype &&
            curform.linktype != 'notlink' &&
            curform.linktype != 'goodslist' &&
            curform.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curform.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="selectLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curform.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curform.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.capsule = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('capsule')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="广告语编辑器"
    v-model="isshow.adslogan"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.adslogan"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="背景图" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.src"
            :src="curform.src"
            class="avatar"
            preview-src-list="[curform.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >胶囊背景图最佳尺寸：702px × 26px</el-tag
        >
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.adslogan = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('adslogan')"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="橱窗编辑器"
    v-model="isshow.showcase"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.showcase"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.showcase = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('showcase')"
          >确 定</el-button
        >
      </span>
    </template>
  </el-dialog>

  <el-dialog
    title="专区编辑器"
    v-model="isshow.special"
    width="40%"
    @close="onCloseBlock"
  >
    <el-form
      :model="curform"
      label-width="100px"
      class="dataform"
      :rules="rules.special"
      ref="dataform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curform.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="背景图" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curform.src"
            :src="curform.src"
            class="avatar"
            preview-src-list="[curform.src]"
            hide-on-click-modal="{{true}}"
            style="max-width: 350px"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >专区背景图最佳尺寸：350px × 280px</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curform.linktype" @change="changeLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="
            curform.linktype &&
            curform.linktype != 'notlink' &&
            curform.linktype != 'goodslist' &&
            curform.linktype != 'link'
          "
          class="tab-header-contorl"
        >
          <el-input
            v-model="curform.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="selectLink" class="sel-btn"
            >选择</el-button
          >
        </div>
        <div v-else-if="curform.linktype == 'link'" class="tab-header-contorl">
          <el-input
            v-model="curform.link"
            autocomplete="off"
            class="readinput"
            placeholder="请输入链接地址"
          ></el-input>
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.special = false">取 消</el-button>
        <el-button type="primary" @click="saveForm('special')">确 定</el-button>
      </span>
    </template>
  </el-dialog>

  <BlockEditor
    :blocktitle="
      isshow.venue ? '主场活动编辑器' : isshow.special ? '专区编辑器' : ''
    "
    :blockdata="curform"
    :blockshow="isshow.venue"
    v-on:closeblock="onCloseBlockEditor"
  ></BlockEditor>

  <LinkBlock
    :linktype="curlinktype"
    :showlink="showlink"
    :curgoodsid="curgoodsid"
    :curlink="curlink"
    v-on:close="onClose"
    v-on:submit="setLink"
  ></LinkBlock>

  <el-dialog
    title="集合导航编辑"
    v-model="isshow.goodslist"
    width="40%"
    @close="closeGoodsMenu"
  >
    <el-form
      :model="curgoodsmenu"
      :rules="rules.goodsmenu"
      ref="dataform"
      label-width="100px"
      class="dataform"
    >
      <el-form-item class="block-form-item" label="主标题" prop="maintitle">
        <el-input
          v-model="curgoodsmenu.maintitle"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item class="block-form-item" label="是否显示">
        <el-radio-group v-model="curgoodsmenu.mainshow">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item class="block-form-item" label="副标题" prop="maintitle">
        <el-input v-model="curgoodsmenu.subtitle" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="block-form-item" label="是否显示">
        <el-radio-group v-model="curgoodsmenu.subshow">
          <el-radio-button :label="1">是</el-radio-button>
          <el-radio-button :label="0">否</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="主题图片">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="imageUploadSuccess"
          :on-change="imageUploadChange"
          :on-error="imgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curgoodsmenu.thumb"
            :src="curgoodsmenu.thumb"
            class="avatar"
            preview-src-list="[curgoodsmenu.thumb]"
            hide-on-click-modal="{{true}}"
            style="max-height: 70px; max-width: 70px; width: auto; height: auto"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger">图片最佳尺寸：70px × 70px</el-tag>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow.goodslist = false">取 消</el-button>
        <el-button type="primary" @click="saveGoodsMenu">保 存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElMessage, ElMessageBox } from "element-plus";
import pageconst from "@/pageconst";
import LinkBlock from "@/components/LinkBlock";
import BlockEditor from "@/components/BlockEditor";

export default {
  data() {
    return {
      isload: false,
      uploadAction: pageconst.upload_pdt_detail_url,
      activeName: "banner",
      config: [],
      banner: [],
      adslogan: [],
      icon: [],
      news: [],
      venue: [],
      special: [],
      capsule: [],
      showcase: [],
      goodslist: [],
      pdtlist: [],
      isshow: {
        banner: false,
        adslogan: false,
        icon: false,
        news: false,
        venue: false,
        special: false,
        capsule: false,
        showcase: false,
        goodslist: false,
      },
      curform: [],
      orgform: [],
      rules: {
        banner: {
          title: [
            { required: true, message: "请输入 Banner 标题", trigger: "blur" },
          ],
          src: [
            { required: true, message: "请上传 Banner 图", trigger: "blur" },
          ],
        },
        adslogan: {
          title: [
            { required: true, message: "请输入广告语标题", trigger: "blur" },
          ],
          src: [
            { required: true, message: "请上传广告语背景图", trigger: "blur" },
          ],
        },
        icon: {
          title: [
            { required: true, message: "请输入导航标题", trigger: "blur" },
          ],
          src: [{ required: true, message: "请上传导航图标", trigger: "blur" }],
        },
        news: {
          title: [
            { required: true, message: "请输入快讯标题", trigger: "blur" },
          ],
        },
        capsule: {
          title: [
            { required: true, message: "请输入胶囊标题", trigger: "blur" },
          ],
          src: [
            { required: true, message: "请上传胶囊背景图", trigger: "blur" },
          ],
        },
        special: {
          title: [
            { required: true, message: "请输入专区标题", trigger: "blur" },
          ],
          src: [
            { required: true, message: "请上传专区背景图", trigger: "blur" },
          ],
        },
        showcase: {
          title: [
            { required: true, message: "请输入橱窗标题", trigger: "blur" },
          ],
        },
        goodsmenu: {
          maintitle: [
            { required: true, message: "请输主标题", trigger: "blur" },
          ],
          subtitle: [
            { required: true, message: "请输副标题", trigger: "blur" },
          ],
        },
      },
      linktype: [
        {
          value: "notlink",
          label: "不跳转",
        },
        {
          value: "page",
          label: "页面跳转",
        },
        {
          value: "goods",
          label: "商品",
        },
        {
          value: "goodslist",
          label: "商品集合页",
        },
        {
          value: "link",
          label: "链接",
        },
      ],
      curlinktype: "notlink",
      showlink: false,
      curgoodsid: "",
      curlink: "",
      curblocktype: "",
      curgoodsmenu: {},
      orggoodsmenu: "",
      curgoodsmenuindex: "",
    };
  },
  components: {
    LinkBlock,
    BlockEditor,
    //Showcase,
  },
  methods: {
    publishPage() {
      // 发布页面、检测是否符合条件，不符合则提示相应错误信息
      console.log("发布页面");
      let adslogancount = 0;
      for (let i = 0; i < this.adslogan.length; i++) {
        if (this.adslogan[i].status * 1 == 1) {
          adslogancount++;
        }
      }
      if (adslogancount > 1) {
        ElMessage.error({
          showClose: true,
          message: "最多只能启用 1 个广告语，请重新设置",
        });
        this.activeName = "adslogan";
        return false;
      }

      let iconcount = 0;
      for (let i = 0; i < this.icon.length; i++) {
        if (this.icon[i].status * 1 == 1) {
          iconcount++;
        }
      }
      if (iconcount > 10) {
        ElMessage.error({
          showClose: true,
          message: "最多只能启用 10 个 ICON 导航，请重新设置",
        });
        this.activeName = "icon";
        return false;
      }

      let newscount = 0;
      for (let i = 0; i < this.news.length; i++) {
        if (this.news[i].status * 1 == 1) {
          newscount++;
        }
      }
      if (newscount > 3) {
        ElMessage.error({
          showClose: true,
          message: "最多只能启用 3 个快讯，请重新设置",
        });
        this.activeName = "news";
        return false;
      }

      let capsulecount = 0;
      for (let i = 0; i < this.capsule.length; i++) {
        if (this.capsule[i].status * 1 == 1) {
          capsulecount++;
        }
      }
      if (capsulecount > 2) {
        ElMessage.error({
          showClose: true,
          message: "最多只能启用 2 个胶囊，请重新设置",
        });
        this.activeName = "capsule";
        return false;
      }

      let specialcount = 0;
      for (let i = 0; i < this.special.length; i++) {
        if (this.special[i].status * 1 == 1) {
          specialcount++;
        }
      }
      if (specialcount != 2 && specialcount != 4) {
        ElMessage.error({
          showClose: true,
          message: "只能同时启用 2 个或 4 个专区，请重新设置",
        });
        this.activeName = "special";
        return false;
      }

      this.axios
        .get(pageconst.pagemanage + "?entry=publish&pagename=index")
        .then((response) => {
          if (response.data == "SUCCESS") {
            ElMessage({
              message: "保存成功",
              type: "success",
            });
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
    },

    handleClick({ props }) {
      for (var key in this.isshow) {
        this.isshow[key] = false;
      }

      //this.saveConfig(this.activeName);

      let { name } = props;

      this.$router.push({
        path: "/page/index",
        query: { tab: name },
      });
    },
    onCloseBlock() {
      this.isshow[this.curblocktype] = false;
      this.config[this.curblocktype].value[this.curform.index] = JSON.parse(
        this.orgform
      );
    },
    saveConfig(type) {
      if (type == "goodslist") {
        this.config[type] = this[type];
      } else {
        this.config[type].value = this[type];
      }
      //console.log(this.config);
      //let that = this;
      this.axios
        .post(
          pageconst.pagemanage + "?entry=saveconfig&pagename=index",
          JSON.stringify(this.config),
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        )
        .then((response) => {
          if (response.data == "SUCCESS") {
            console.log("保存成功");
            //that.Init();
          } else {
            ElMessage.error({
              showClose: true,
              message: response.data,
            });
            return false;
          }
        });
    },
    addItem(type, index = "") {
      if (index !== "") {
        this.curform = this[type][index];
        this.curform.index = index;

        this.orgform = JSON.stringify(this[type][index]);
      } else {
        this.curform = {};
        this.curform.index = "";
        this.curform.status = 0;

        if (type == "area") this.curform.type = "layoutblock";

        this.orgform = JSON.stringify({});
      }

      if (type != "area" && type != "showcase") {
        if (!this.curform.linktype) {
          this.curform.linktype = "notlink";
          this.curform.linkname = "不跳转";
        } else {
          this.curlinktype = this.curform.linktype;
        }
      }

      this.curblocktype = type;
      this.showlink = false;

      for (var key in this.isshow) {
        if (key == type) this.isshow[key] = true;
        else this.isshow[key] = false;
      }
    },
    delItem(type, index) {
      var that = this,
        curblock = this[type];

      if (!curblock[index]) return false;

      ElMessageBox.confirm(
        "您确定要删除 " + curblock[index].title + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          curblock.splice(index, 1);
          that[type] = curblock;
          that.saveConfig(type);
        })
        .catch(() => {
          console.log("取消操作");
        });
    },
    swapArray(arr, index1, index2) {
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    moveUp(type, index) {
      var curblock = this[type];
      if (index != 0) {
        if (type == "goodslist") {
          this.goodslist.items = this.swapArray(
            this.goodslist.items,
            index,
            index - 1
          );
        } else {
          this[type] = this.swapArray(curblock, index, index - 1);
        }
      }
      this.saveConfig(type);
    },
    moveDown(type, index) {
      var curblock = this[type];
      if (index + 1 != curblock.length) {
        if (type == "goodslist") {
          this.goodslist.items = this.swapArray(
            this.goodslist.items,
            index,
            index + 1
          );
        } else {
          this[type] = this.swapArray(curblock, index, index + 1);
        }
      }
      this.saveConfig(type);
    },
    imageUploadChange(file) {
      console.log("image upload change ===>");

      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      if (!isSubmit) {
        if (this.isshow.goodslist) {
          this.$message.error("主题图片大小不能超过 2MB!");
        } else {
          this.$message.error("产品缩略图大小不能超过 2MB!");
        }
        return false;
      }

      if (isSubmit) {
        console.log(this.$refs.imageUpload.submit());
      }
    },
    imageUploadSuccess(res) {
      console.log("image upload success ===> ", this.curblocktype, res);
      if (this.curblocktype == "area" || this.curblocktype == "showcase") {
        // 区域背景
        this.curitem.bgimage = res.url;
      } else if (this.isshow.goodslist) {
        this.curgoodsmenu.thumb = res.url;
      } else {
        this.curform.src = res.url;
      }
    },
    imgUploadError(err) {
      console.log("err==>");
      console.log(err);
    },
    saveForm(type) {
      // 保存当前模块数据
      var that = this;
      console.log(this.curform);
      this.$refs["dataform"].validate((res) => {
        if (res) {
          var curindex = that.curform.index;
          console.log(curindex);
          delete that.curform.index;
          if (curindex !== "") {
            // 更新当前模块
            that[type][curindex] = that.curform;
          } else {
            // 新增模块
            that[type].push(that.curform);
          }
          that.saveConfig(type);
          that.isshow[type] = false;
        }
      });
    },
    changeLinkType(e) {
      //console.log(e);
      this.curlinktype = e;
      this.curform.link = "";
      if (e != "notlink" && e != "goodslist" && e != "link") {
        this.selgoods = "radio";
        this.curform.linkname = "";
        this.showlink = true;
      } else if (e == "goodslist") {
        this.curform.linkname = "商品集合页";
      } else if (e == "link") {
        this.curform.linkname = "自定义链接";
      } else {
        this.curform.linkname = "不跳转";
      }
    },
    onClose(isclose) {
      this.showlink = isclose;
    },
    onCloseBlockEditor(isclose, blockdata = null) {
      console.log(blockdata);
      this.isshow[this.curblocktype] = isclose;
      if (blockdata != null) {
        if (this.curform.index !== "")
          this[this.curblocktype][this.curform.index] = blockdata;
        else this[this.curblocktype].push(blockdata);

        delete this.curform.index;
        this.saveConfig(this.curblocktype);
      }
    },
    setLink(link, linkname, goodsid = "") {
      if (this.curblocktype != "area" && this.curblocktype != "showcase") {
        this.curform.link = link;
        this.curform.linkname = linkname;
        this.curform.goodsid = goodsid;
      }
    },
    selectLink() {
      if (this.curblocktype != "area" && this.curblocktype != "showcase") {
        this.curlinktype = this.curform.linktype;
        this.curgoodsid = this.curform.goodsid;
        this.curlink = this.curform.link;
        this.showlink = true;
      }
    },

    onCloseShowcase(isclose, blockdata) {
      this.isshow[this.curblocktype] = isclose;
      console.log(blockdata);
    },

    updateStatus(type, index) {
      let curstatus = this[type][index].status,
        title = this[type][index].title;

      if (curstatus == 1) {
        // 询问
        let that = this;
        ElMessageBox.confirm("您确定要停用 " + title + " 吗？", "系统提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            that[type][index].status = 0;
            that.saveConfig(type);
          })
          .catch((error) => {
            console.log("取消操作", error);
          });
      } else {
        this[type][index].status = 1;
        this.saveConfig(type);
      }
    },

    selGoods(type, index) {
      this.$router.push({
        path: "/page/goodsmanage",
        query: { pagename: "index", type: type, index: index },
      });
    },

    contentManagement(type, index) {
      if (type == "menus" || type == "ad") {
        this.$router.push({
          path: "/page/menumanage",
          query: { pagename: "index", type: type, index: index },
        });
      } else {
        this.$router.push({
          path: "/page/contentmanage",
          query: { pagename: "index", type: type, index: index },
        });
      }
    },

    // 新增商品集合
    addGoods() {
      this.selGoods("goodslist", 0);
    },
    getGoodsList() {
      let that = this,
        pdtids =
          this.goodslist.length > 0 && this.goodslist[0].goodsids.length > 0
            ? this.goodslist[0].goodsids
            : [];

      if (pdtids.length > 0) {
        let newpdtids = new Array();
        for (let i = 0; i < pdtids.length; i++) {
          if (pdtids[i].status * 1 == 1) {
            newpdtids.push(pdtids[i].id);
          }
        }
        this.loading = true;
        this.axios
          .post(
            pageconst.pagemanage +
              "?entry=getgoodslist&pagesize=" +
              newpdtids.length +
              "&goodsids=" +
              JSON.stringify(newpdtids),
            null,
            {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded",
              },
            }
          )
          .then(({ data }) => {
            let { list } = data;
            that.pdtlist = list;
            that.loading = false;
            console.log(list);
          });
      }
    },

    changeListMode(val) {
      if (val == 2) {
        this.goodslist.column = 1;
      }
      this.saveConfig("goodslist");
    },

    // 编辑集合导航
    addGoodsMenu(id = "") {
      this.curgoodsmenuindex = id;
      if (!isNaN(id * 1)) {
        this.curgoodsmenu = this.goodslist.items[id];
        this.orggoodsmenu = JSON.stringify(this.curgoodsmenu);
      } else {
        this.curgoodsmenu = {};
        this.curgoodsmenu.maintitle = "";
        this.curgoodsmenu.mainshow = 1;
        this.curgoodsmenu.subtitle = "";
        this.curgoodsmenu.subshow = 1;
        this.curgoodsmenu.thumb = "";
        this.curgoodsmenu.status = 0;
        this.orggoodsmenu = JSON.stringify({});
      }
      console.log(this.curgoodsmenu);
      this.isshow.goodslist = true;
    },

    closeGoodsMenu() {
      this.isshow.goodslist = false;
      if (!isNaN(this.curgoodsmenuindex * 1) && this.curgoodsmenuindex >= 0) {
        this.goodslist.items[this.curgoodsmenuindex] = JSON.parse(
          this.orggoodsmenu
        );
        this.curgoodsmenuindex = "";
      }
    },

    saveGoodsMenu() {
      let that = this;
      this.$refs["dataform"].validate((valid) => {
        console.log(valid, that.curgoodsmenuindex * 1);
        if (valid) {
          if (isNaN(that.curgoodsmenuindex * 1)) {
            // 新增集合导航项
            if (!that.goodslist.items) {
              that.goodslist.items = [];
            }
            that.goodslist.items.push(that.curgoodsmenu);
          } else {
            // 修改集合导航
            that.goodslist.items[that.curgoodsmenuindex] = that.curgoodsmenu;
          }
          that.curgoodsmenuindex = "";
          that.saveConfig("goodslist");
          that.isshow.goodslist = false;
        }
      });
    },

    updateGoodsMenuStatus(index) {
      if (isNaN(index) || index < 0) {
        console.log("索引出错");
        return false;
      }

      let curgoodsmenu = this.goodslist.items[index],
        curstatus = curgoodsmenu.status * 1;
      if (curstatus == 1) {
        // 询问
        let that = this;
        ElMessageBox.confirm(
          "您确定要停用 " + curgoodsmenu.maintitle + " 吗？",
          "系统提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        )
          .then(() => {
            that.goodslist.items[index].status = 0;
            that.saveConfig("goodslist");
          })
          .catch((error) => {
            console.log("取消操作", error);
          });
      } else {
        this.goodslist.items[index].status = 1;
        this.saveConfig("goodslist");
      }
    },

    deleteGoodsMenu(index) {
      if (isNaN(index) || index < 0) {
        console.log("索引出错");
        return false;
      }

      let curgoodsmenu = this.goodslist.items[index];

      if (curgoodsmenu.status * 1 == 1) {
        ElMessage.error(curgoodsmenu.maintitle + " 处于启用状态，无法删除！");
        return false;
      }

      let that = this;
      ElMessageBox.confirm(
        "您确定要删除" + curgoodsmenu.maintitle + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          that.goodslist.items.splice(index, 1);
          that.saveConfig("goodslist");
        })
        .catch(() => {
          console.log("取消操作");
        });
    },

    Init() {
      let { tab } = this.$route.query;
      if (!tab) tab = "banner";
      this.activeName = tab;

      let that = this;
      this.axios
        .get(pageconst.pagemanage + "?entry=getconfig&pagename=index", {
          headers: { "Content-Type": "application/json" },
        })
        .then(({ data }) => {
          var { config } = data;
          console.log("config => ", config);
          that.config = config;
          that.banner = config.banner.value;
          that.adslogan = config.adslogan.value;
          that.icon = config.icon.value;
          that.news = config.news.value;
          that.venue = config.venue.value;
          that.special = config.special.value;
          that.capsule = config.capsule.value;
          that.showcase = config.showcase.value;
          that.goodslist = config.goodslist ? config.goodslist : {};
          if (!that.goodslist.mode) {
            that.goodslist.mode = 1;
          }
          if (!that.goodslist.column) {
            that.goodslist.column = 2;
          }
          that.isload = true;
        });
    },
  },
  created: function () {
    this.Init();
  },
};
</script>

<style scoped>
.content-main {
  border: 1px solid #ebeef5;
  border-bottom: none;
  margin: 20px 0;
}

.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

>>> .row-control {
  text-align: right;
}

>>> .el-table td {
  padding: 8px 0;
}

>>> .el-table .cell {
  max-height: 50px;
}

.cell .el-button {
  margin-right: 15px;
  margin-left: 0;
}
.cell .el-button:last-child {
  margin-right: 0;
}

.cell .el-button.delete {
  color: #f66;
}

.page-settings .pdt-thumb {
  height: 50px;
  padding: 2px;
  border: 1px solid #ddd;
}
>>> .page-settings .pdt-thumb img {
  width: auto;
  height: 100%;
}

.page-settings .ads-thumb {
  width: 100%;
  padding: 2px;
  border: 1px solid #ddd;
  display: flex;
  align-items: center;
}
>>> .page-settings .ads-thumb img {
  width: 100%;
  height: auto;
}

.readinput,
.sel-btn {
  margin-top: 10px;
}
.sel-btn {
  margin-left: 10px;
}

.dataform >>> .avatar-uploader {
  line-height: 0;
}

.dataform >>> .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.dataform >>> .avatar {
  width: 100%;
  max-width: 400px;
  display: block;
  /*border-radius: 4px;*/
  border: 1px solid #dcdfe6;
}

.ad-thumb {
  box-sizing: border-box;
  height: 40px;
  width: 40px;
  padding: 2px;
  border: 1px solid #ddd;
}
</style>