<!--

<BlockEditor
    :blocktitle="标题"
    :blockdata="curform"
    :blockshow="isshow"
    v-on:closeblock="onCloseBlockEditor"
  ></BlockEditor>

curform 数据 
  {
    "title": "标题",
    "type": "layoutblock",
    "bgimage": "区域块背景图片",
    "height": "区域块高度",
    "value": [
      {
        "title": "内容块标题",
        "column": "内容块列数",
        "height": "内容块高度",
        "items": [
          {
            "title": "内容标题",
            "link": "内容链接",
            "linkname": "链接标题（自动填充）",
            "src": "内容背景图"
          },
          ...
        ]
      }
    ]
  }

isshow 是否显示 true/false

onCloseBlockEditor 关闭区域块编辑器回调函数
onCloseBlockEditor(isclose, blockdata=null){}

-->

<template>
  <el-dialog
    v-if="!editblock && !edititem"
    :title="blocktitle"
    v-model="isshow"
    width="40%"
    @close="closeBlockEditor"
  >
    <el-form :model="formdata" label-width="100px" class="dataform">
      <el-form-item class="block-form-item" label="标题" prop="title">
        <el-input v-model="formdata.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item class="block-form-item" label="区域背景" prop="src">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="bgimageUploadSuccess"
          :on-change="bgimageUploadChange"
          :on-error="bgimgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="bgimageUpload"
        >
          <img
            v-if="formdata.bgimage"
            :src="formdata.bgimage"
            class="avatar"
            preview-src-list="[formdata.bgimage]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >区域背景最佳尺寸：宽度750px，高度自定义</el-tag
        >
      </el-form-item>
      <el-form-item label="区域高度">
        <el-input-number
          v-model="formdata.height"
          controls-position="right"
        ></el-input-number>
        px
        <el-tag size="small" type="danger"
          >区域高度一经设定，区域内容将不会自动撑开该区域</el-tag
        >
      </el-form-item>

      <!--<el-table
        class="block-contents"
        :data="blockdata.value"
        v-loading="loading"
        element-loading-text="拼命加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        size="small"
      >
        <el-table-column prop="title" label="区域内容" show-overflow-tooltip />
        <el-table-column prop="column" label="内容列数" />
        <el-table-column prop="height" label="内容高度(px)" />

        <el-table-column label="操作" width="200">
          <template #header>
            <div class="tab-header-contorl">
              <label>操作</label>
              <el-button type="success" size="medium" @click="baddItem()"
                >新增</el-button
              >
            </div>
          </template>
          <template #default="scope">
            <el-button type="text" @click="baddItem(scope.$index)"
              >编辑</el-button
            >
            <el-button type="text" @click="bmoveUp(scope.$index)"
              >上移</el-button
            >
            <el-button type="text" @click="bmoveDown(scope.$index)"
              >下移</el-button
            >
            <el-button
              type="text"
              @click="bdelItem(scope.$index)"
              class="delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>-->
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="isshow = false">取 消</el-button>
        <el-button type="primary" @click="saveForm">保 存</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="editblock && !edititem"
    title="区域内容编辑器"
    v-model="editblock"
    width="40%"
    @close="closeBlock"
  >
    <el-form
      :model="curblock"
      label-width="100px"
      class="curform"
      :rules="rules"
      ref="blockform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curblock.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="内容高度" prop="height">
        <el-input-number
          v-model="curblock.height"
          controls-position="right"
          :min="1"
        ></el-input-number>
        px
        <el-tag size="small" type="danger">每行内容的高度</el-tag>
      </el-form-item>
      <el-form-item label="内容列数" prop="column">
        <el-input-number
          v-model="curblock.column"
          controls-position="right"
          :min="1"
          :max="5"
        ></el-input-number>
        px
        <el-tag size="small" type="danger"
          >每行显示的内容项数量，最多5列</el-tag
        >
      </el-form-item>

      <el-table
        class="block-contents"
        :data="curblock.items"
        v-loading="loading"
        element-loading-text="拼命加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        size="small"
      >
        <el-table-column prop="title" label="内容项标题" show-overflow-tooltip />
        <el-table-column prop="linkname" label="链接" show-overflow-tooltip />

        <el-table-column label="操作" width="200">
          <template #header>
            <div class="tab-header-contorl">
              <label>操作</label>
              <el-button type="success" size="medium" @click="baddItem()"
                >新增</el-button
              >
            </div>
          </template>
          <template #default="scope">
            <el-button type="text" @click="baddItem(scope.$index)"
              >编辑</el-button
            >
            <el-button type="text" @click="bmoveUp(scope.$index)"
              >上移</el-button
            >
            <el-button type="text" @click="bmoveDown(scope.$index)"
              >下移</el-button
            >
            <el-button
              type="text"
              @click="bdelItem(scope.$index)"
              class="delete"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeBlock">取 消</el-button>
        <el-button type="primary" @click="saveBlock">保存设置</el-button>
      </span>
    </template>
  </el-dialog>

  <el-dialog
    v-if="edititem"
    title="内容项编辑器"
    v-model="edititem"
    width="40%"
    @close="closeItem"
  >
    <el-form
      :model="curitem"
      label-width="100px"
      class="dataform"
      ref="itemform"
    >
      <el-form-item label="标题" prop="title">
        <el-input v-model="curitem.title" autocomplete="off"></el-input>
      </el-form-item>
      <el-form-item label="背景图" prop="img">
        <el-upload
          class="avatar-uploader"
          :action="uploadAction"
          :on-success="bgimageUploadSuccess"
          :on-change="bgimageUploadChange"
          :on-error="bgimgUploadError"
          :show-file-list="false"
          :auto-upload="false"
          ref="imageUpload"
        >
          <img
            v-if="curitem.src"
            :src="curitem.src"
            class="avatar"
            :preview-src-list="[curitem.src]"
            hide-on-click-modal="{{true}}"
          />
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-tag size="small" type="danger"
          >背景图高度请保持与设定的内容高度一致</el-tag
        >
      </el-form-item>
      <el-form-item label="链接">
        <el-select v-model="curitem.linktype" @change="changeItemLinkType">
          <el-option
            v-for="item in linktype"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          >
          </el-option>
        </el-select>
        <div
          v-if="curitem.linktype && curitem.linktype != 'notlink'"
          class="tab-header-contorl"
        >
          <el-input
            v-model="curitem.linkname"
            autocomplete="off"
            readonly="true"
            class="readinput"
          ></el-input>
          <el-button type="success" @click="itemSelLink" class="sel-btn"
            >选择</el-button
          >
        </div>
      </el-form-item>
    </el-form>

    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closeItem">取 消</el-button>
        <el-button type="primary" @click="saveItem">保存内容项</el-button>
      </span>
    </template>
  </el-dialog>

  <LinkBlock
    :v-if="itemshowlink"
    :linktype="itemlinktype"
    :showlink="itemshowlink"
    :curgoodsid="itemgoodsid"
    :curlink="itemlink"
    v-on:close="onClose"
    v-on:submit="setLink"
  ></LinkBlock>
</template>


<script>
import { ElMessageBox } from "element-plus";
import pageconst from "@/pageconst";
import LinkBlock from "@/components/LinkBlock";

export default {
  name: "blockeditor",
  props: {
    blocktitle: {
      type: String,
    },
    blockshow: {
      type: Boolean,
    },
    blockdata: {
      type: Object,
    },
  },
  watch: {
    blockshow: function (newValue, oldValue) {
      console.log("blockshow: " + newValue, oldValue);
      this.isshow = newValue;
    },
    blockdata: function (newValue, oldValue) {
      console.log("blockdata==>", oldValue);
      this.formdata = newValue;
      //console.log(this.formdata);
    },
  },
  data() {
    return {
      uploadAction: pageconst.upload_pdt_detail_url,
      linktype: [
        {
          value: "notlink",
          label: "不跳转",
        },
        {
          value: "page",
          label: "页面跳转",
        },
        {
          value: "goods",
          label: "商品",
        },
      ],
      isshow: false,
      formdata: {},
      showlink: false,
      curlink: "",
      curgoodsid: "",
      editblock: false,
      curblockindex: "",
      curblock: {},
      orgblock: {},
      rules: {
        title: [
          { required: true, message: "请输入区域内容标题", trigger: "blur" },
        ],
      },
      edititem: false,
      curitemindex: "",
      curitem: {},
      orgitem: {},
      itemlinktype: "",
      itemshowlink: false,
      itemgoodsid: "",
      itemlink: "",
    };
  },
  components: {
    LinkBlock,
  },
  methods: {
    closeBlockEditor() {
      console.log("closeBlockEditor");
      this.isshow = false;
      this.$emit("closeblock", false);
    },
    bgimageUploadChange(file) {
      let isSubmit = file.raw.size / 1024 / 1024 < 2;
      if (!isSubmit) {
        this.$message.error("图片大小不能超过 2MB!");
        return false;
      }

      if (isSubmit) this.$refs.bgimageUpload.submit();
    },
    bgimageUploadSuccess(res) {
      console.log("image upload success ===> " + res.url);
      if (!this.editblock) {
        // 区域背景
        this.formdata.bgimage = res.url;
      } else {
        this.curitem.src = res.url;
      }
    },
    bgimgUploadError(err) {
      console.log("err==>");
      console.log(err);
    },
    baddItem(index = "") {
      if (!this.editblock) {
        this.curblockindex = index;
        if (index !== "") {
          this.curblock = this.formdata.value[index];
          this.orgblock = JSON.stringify(this.formdata.value[index]);
        } else {
          this.curblock = {};
          this.curblock.items = [];
          this.orgblock = JSON.stringify({});
        }

        if (this.curblock.height)
          this.curblock.height = this.curblock.height * 1;
        if (this.curblock.column)
          this.curblock.column = this.curblock.column * 1;

        this.editblock = true;
      } else {
        this.curitemindex = index;
        if (index !== "") {
          this.curitem = this.curblock.items[index];
          this.orgitem = JSON.stringify(this.curblock.items[index]);
        } else {
          this.curitem = {};
          this.orgitem = JSON.stringify({});
        }

        if (!this.curitem.linktype) this.curitem.linktype = "notlink";
        if (!this.curitem.linkname) this.curitem.linkname = "不跳转";

        this.edititem = true;
      }
    },
    bdelItem(index) {
      var that = this,
        curblock = [];
      if (!that.editblock) curblock = that.formdata.value;
      else curblock = that.curblock.items;
      ElMessageBox.confirm(
        "您确定要删除 " + curblock[index].title + " 吗？",
        "系统提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(() => {
          if (!that.editblock) {
            curblock.splice(index, 1);
            that.formdata.value = curblock;
          } else {
            curblock.splice(index, 1);
            that.curblock.items = curblock;
          }
        })
        .catch(() => {
          console.log("取消操作");
        });
    },
    bswapArray(arr, index1, index2) {
      console.log(arr);
      arr[index1] = arr.splice(index2, 1, arr[index1])[0];
      return arr;
    },
    bmoveUp(index) {
      var curarr = [];
      if (!this.editblock) {
        curarr = this.formdata.value;
        if (index != 0) {
          this.formdata.value = this.bswapArray(curarr, index, index - 1);
        }
      } else {
        curarr = this.curblock.items;
        if (index != 0) {
          this.curblock.items = this.bswapArray(curarr, index, index - 1);
        }
      }
    },
    bmoveDown(index) {
      var curarr = [];
      if (!this.editblock) {
        curarr = this.formdata.value;
        if (index + 1 != curarr.length) {
          this.formdata.value = this.bswapArray(curarr, index, index + 1);
        }
      } else {
        curarr = this.curblock.items;
        if (index + 1 != curarr.length) {
          this.curblock.items = this.bswapArray(curarr, index, index + 1);
        }
      }
    },
    saveForm() {
      console.log("blocksave", this.formdata);
      if(!this.formdata.type) this.formdata.type = "layoutblock";
      if(!this.formdata.bgimage) this.formdata.bgimage = "";
      if(!this.formdata.height) this.formdata.height = 0;
      if(!this.formdata.value) this.formdata.value = [];
      
      this.isshow = false;
      this.$emit("closeblock", false, this.formdata);
    },

    closeBlock() {
      this.editblock = false;
      if (this.curblockindex !== "")
        this.formdata.value[this.curblockindex] = JSON.parse(this.orgblock);
    },
    saveBlock() {
      console.log(this.curblock);
      if(this.curblockindex !== "")
        this.formdata.value[this.curblockindex] = this.curblock;
      else
        this.formdata.value.push(this.curblock);

      this.editblock = false;
    },

    closeItem() {
      console.log(this.editblock);
      this.edititem = false;
      if (this.curitemindex !== "")
        this.curblock.items[this.curitemindex] = JSON.parse(this.orgitem);
    },
    saveItem(){
      if(this.curitemindex !== "")
        this.curblock.items[this.curitemindex] = this.curitem;
      else
        this.curblock.items.push(this.curitem);
      
      this.edititem = false;
    },

    onClose(isclose) {
      this.itemshowlink = isclose;
    },
    setLink(link, linkname, goodsid = "") {
      console.log(link, linkname, goodsid);
      this.curitem.link = link;
      this.curitem.linkname = linkname;
      this.curitem.goodsid = goodsid;
    },
    itemSelLink() {
      this.itemlinktype = this.curitem.linktype;
      this.itemgoodsid = this.curitem.goodsid;
      this.itemlink = this.curitem.link;
      this.itemshowlink = true;
    },
    changeItemLinkType(e) {
      this.itemlinktype = e;
      if (e != "notlink") {
        //this.curitem.linktype = e;
        this.curitem.linkname = "";
        this.itemshowlink = true;
      } else {
        this.curitem.linkname = "不跳转";
      }
    },
  },
  mounted() {
    console.log(this.blockdata);
  },
};
</script>

<style scoped>
.block-form-item {
  margin-bottom: 10px;
}

.block-input {
  width: 400px;
}

.tab-header-contorl {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dataform {
  padding-right: 6px;
  max-height: 540px;
  overflow-y: scroll;
}
.dataform::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
.dataform::-webkit-scrollbar-thumb {
  background-color: rgba(0,0 ,0, .4);
  border-radius: 3px;
}

.dataform >>> .avatar-uploader {
  line-height: 0;
}

.dataform >>> .avatar-uploader-icon {
  font-size: 40px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}
.dataform >>> .avatar {
  width: 100%;
  max-width: 300px;
  display: block;
  border-radius: 4px;
  border: 1px solid #dcdfe6;
}

.readinput,
.sel-btn {
  margin-top: 10px;
}
.sel-btn {
  margin-left: 10px;
}

.block-contents {
  border: 1px solid #ebeef5;
  border-bottom: 0;
}

>>> .block-contents th {
  background-color: #f3f3f3;
  color: #909399;
}

.cell .el-button {
  margin-right: 10px;
}
.cell .el-button:last-child {
  margin-right: 0;
}

.cell .el-button.delete {
  color: #f66;
}
</style>